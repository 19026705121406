import React, { useContext } from 'react'
import { Container, Box, Flex } from 'theme-ui'
import pageContextProvider from '@helpers/pageContextProvider'
import Search from '@widgets/Search'
import { HeaderLogo } from './Header.Logo'
import { HeaderMenu } from './Header.Menu'
import { HeaderColorMode } from './Header.ColorMode'
import HoverControlledDropdown from '@components/HoverControlledDropdown'
import Dropdown from 'react-bootstrap/Dropdown'
import './upmyc-dropdown.css'
import { RiArticleLine } from 'react-icons/ri'
import { RiLightbulbLine } from 'react-icons/ri'
import { RiRocket2Line } from 'react-icons/ri'
import { RiMoneyDollarCircleLine } from 'react-icons/ri'


const styles = {
  wrapper: {
    position: `relative`,
    bg: `headerBg`
  },
  container: {
    position: `relative`,
    zIndex: 10
  },
  logoContainer: {
    flexBasis: [`full`, `full`, `1/3`]
  },
  searchContainer: {
    flexBasis: [`auto`, `auto`, `1/3`],
    minWidth: `auto`,
    order: [3, 3, `unset`],
    mx: 3
  },
  menuContainer: {
    flexBasis: [`auto`, `auto`, `1/3`],
    minWidth: `auto`,
    order: [4, 4, `unset`]
  },
  colorModeContainer: {
    minWidth: `auto`,
    order: [2, 2, `unset`]
  }
}

export const Header = ({ children }) => {
  const context = useContext(pageContextProvider)

  const locationState =
    context.location && context.location.state && context.location.state.key

  const { services, mobileMenu, darkMode } = context.pageContext

  const algolia = services && services.algolia

  return (
    <Box sx={styles.wrapper}>
      <Container variant='compact' sx={styles.container}>
        <Flex variant='layout.header'>
          <Box sx={styles.logoContainer}>
            <HeaderLogo />
          </Box>
          <Box sx={styles.searchContainer}>
            {algolia && <Search locationState={locationState} />}
          </Box>
          <Box sx={styles.menuContainer}>
            <HeaderMenu locationState={locationState} mobileMenu={mobileMenu} />
          </Box>
<a href="/" className="nav-link" as="button">
    Home
</a>

<a href="/contact" className="nav-link" as="button">
    Dashboard
    </a>


  <HoverControlledDropdown>
  <Dropdown.Toggle variant="success" id="dropdown-basic">
  Our Services
  </Dropdown.Toggle>

  <Dropdown.Menu alignRight> 
  <Box className='dropdown-col'>
  <Dropdown.Header>Content</Dropdown.Header>
    <Dropdown.Item href="/"><RiArticleLine/>Authority Content</Dropdown.Item>
    <Dropdown.Item href="/"><RiMoneyDollarCircleLine/>Sales Copywriting</Dropdown.Item>
    <Dropdown.Item href="/"><RiRocket2Line/>Website Launch Package</Dropdown.Item>
    <Dropdown.Item href="/"><RiLightbulbLine/>Content Strategy</Dropdown.Item>

    <Dropdown.Divider />
    <Dropdown.Header>SEO</Dropdown.Header>
    <Dropdown.Item href="/"><RiArticleLine/>SEO Consulting</Dropdown.Item>
    <Dropdown.Item href="/"><RiMoneyDollarCircleLine/>Link Building</Dropdown.Item>
    </Box>
  </Dropdown.Menu>
</HoverControlledDropdown>

          <Box sx={styles.colorModeContainer}>
            {darkMode && <HeaderColorMode />}
          </Box>
        </Flex>
      </Container>
      {children}
    </Box>
  )
}
